<template>
<div v-if="parentProfile !== null">

  <b-modal id="modal-send" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Send new questionnaire?
    </template>
    <div class="d-block">
      <p>
        <strong>Parent's details:</strong> {{parentProfile.parentFirstName}} {{parentProfile.parentLastName}}<br>
        <strong>Email:</strong> {{parentProfile.parentEmail}}
      </p>
      <p>Your organisation will be charged <strong>1 credit</strong></p>
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-send')">Cancel</button>
        <button class="btn btn-block btn-lg btn-warning ml-1" v-on:click="sendNewQuestionnaire()">Yes, send now</button>
      </div>
    </div>
  </b-modal>


  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-left-open mr-2 pl-0"></span>Dashboard</b-link>
        <div class="d-flex justify-content-between">
          <h2 class="anim-load1 mt-2">{{parentProfile.parentFirstName}} {{parentProfile.parentLastName}} & {{parentProfile.childFirstName}}</h2>
          <div>
            <b-link class="btn btn-warning btn-sm" :to="'/profile/' + id + '/edit'">Edit details</b-link>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ProfileGraph :graphData="graphData" />
          </div> 
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12 col-md-8">
        <div class="card mb-3">
          <div class="card-body">
            <h4 class="mb-3">TAYC-R questionnaires</h4>
            <list-questionnaires 
                :items="taycrData" 
                :perPage="20" 
                :urlSlug="'/profile/' + id + '/report/'"
                urlTarget="questionnaireId"
                sortBy="sentOn"
              />
          </div> 
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <h4>Send new questionnaire</h4>
            <Credits :creditsAv="creditsAv" :displayInfo="false" class="mb-3 mt-3" />
            <b-link class="btn btn-warning btn-block" v-on:click="$bvModal.show('modal-send')" :disabled="creditsAv < 1">Send new questionnaire</b-link>
          </div> 
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

</template>

<script>
import Credits from '@/components/modules/Credits';
import ProfileGraph from '@/components/modules/ProfileGraph';
import ListQuestionnaires from '@/components/modules/ListQuestionnaires';
import accountDetails from '@/mixins/accountDetails'

export default {
  name: 'ProfileView',
  components: {
    Credits, ProfileGraph, ListQuestionnaires
  },
  props: ['id'],
  mixins: [accountDetails],
  data () {
    return {
      taycrData: [],
      graphData: null,
      parentProfile: null
    }
  },
  computed: {
  },
    async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.accountPersonalDetailsGet(),  this.getOrganisationCredits(), this.getParentProfile()]);
    this.$store.state.loadingProgress = false
  },
  beforeDestroy() {
  },
  methods: {
       getParentProfile() {

          const isAdmin = this.$store.state.user.roles.includes('Admin');
          const isSuperOrgAdmin = this.$store.state.user.roles.includes('OrgSuperAdmin');
          console.log("Roles: ", this.$store.state.user.roles);
          const apiCall = (isAdmin || isSuperOrgAdmin) ? `${this.$api.AdminProfileGet}/${this.id}` : this.$api.QuestionnaireProfilesGet;

      this.$http({
        method: 'get', 
        url: apiCall,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {

            const profiles = Array.isArray(response.data) ? response.data : [response.data];

            if (profiles.length > 1) {
                this.parentProfile = response.data.find(profile => profile.id === this.id)
            } else {
                this.parentProfile = profiles[0];
            }

          this.getQuestionnaires()
          if (!this.parentProfile) {
            this.alertMsgShow("Profile doesn't exist", false)
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
          this.$router.push('/dashboard')
        })
    },
      getQuestionnaires() {

          const isAdmin = this.$store.state.user.roles.includes('Admin');
          const isSuperOrgAdmin = this.$store.state.user.roles.includes('OrgSuperAdmin');

          const apiCall = (isAdmin || isSuperOrgAdmin) ? `${this.$api.AdminQuestionnaireAllGet}/${this.parentProfile.id}` : this.$api.QuestionnaireAllGet;

      this.$http({
        method: 'get', 
          url: apiCall,
        params: {
          profileId: this.parentProfile.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.taycrData = response.data
          this.getResults()
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
    },

    getResults () {
      this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireResultsGet,
        params: {
          profileId: this.parentProfile.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.graphData = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
    },
    
    async sendNewQuestionnaire() {
      // Check organisation credits right before submitting
      await this.getOrganisationCredits()
      if (this.creditsAv < 1) {
        this.alertMsgShow('Your organisation run out of credits', false)
        this.$bvModal.hide('modal-send')
        this.$store.state.loadingProgress = false
        return false
      } 

      // send questionnaire, then refresh data on the page to show new item awaiting completion
      this.$bvModal.hide('modal-send')
      this.$store.state.loadingProgress = true
      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireCreate,
        params: {
          profileId: this.parentProfile.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then(async (response) => {
          console.log(response)
          this.alertMsgShow("Questionnaire sent", true);
          await Promise.all([this.accountPersonalDetailsGet(),  this.getOrganisationCredits(), this.getParentProfile()]);
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong. Please try again.', false)
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
    }
  }
}
</script>
