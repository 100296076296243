<template>
  <div>
    <h4 class="mb-3">TAYC-R Progress</h4>
    <div v-if="graphData !== null && completedData.length">
      <VueApexCharts ref="taycr" height="400" :options="options" :series="series" class="survey-graph"></VueApexCharts>
    </div>
    <p class="mb-0" v-else>At least one questionnaire must be completed by the parent to display the TAYC-R progress graph</p>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProfileGraph',
  components: { VueApexCharts },
  props: ['graphData'],
  data () {
    return {
      completedData: [],
      options: {
        chart: {
          height: 400,
          type: 'line',
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: "category",
          tickPlacement: 'on',
          categories: [],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          }
        },
        stroke: {
          width: 2,
          lineCap: 'round',
        },
        markers: {
          size: 4,
          shape: "circle",
          radius: 10,
          hover: {
            sizeOffset: 2
          }
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy"
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
      },
      series: [],
    }
  },
  created() {
  },
  methods: {
    createGraph() {
      var psu = []
      var pcr = []
      var crc = []
      var st = []
      var totalScore = []

      // Block if graphData not provided
      if (this.graphData === null) {
        return false
      }

      this.completedData = this.graphData.filter(item => {
        return item.submittedOn !== null
      })
      var dates = this.completedData.map(item =>{ return  this.$dayjs(item.completed).format('DD MMM YYYY') })

      this.completedData.forEach(item => {
        psu.push(item.psu)
        pcr.push(item.pcr)
        crc.push(item.crc)
        st.push(item.st)
        totalScore.push(item.total)
      });

      this.series = [
        {
          name: 'PSU',
          data: psu
        },
        {
          name: 'PCR',
          data: pcr
        },
        {
          name: 'CRC',
          data: crc
        },
        {
          name: 'ST',
          data: st
        },
        {
          name: 'Total Score',
          data: totalScore
        },
      ]
      this.options.xaxis.categories = dates
    }
  },
  watch: {
    graphData() {
      if (this.graphData !== null) {
        this.createGraph()
      }
    }
  }
}
</script>

