<template>
  <div>
    <b-pagination v-if="itemsComp.length > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="list-users"
      size="sm"
    ></b-pagination>

    <ul class="list-unstyled" id="list-users" v-if="itemsComp.length > 0">
      <li v-for="item in itemsForList" v-bind:key="item.questionnaireId">
        <b-link 
          title="View" class="border-bottom d-flex justify-content-between py-2 btn-list" 
          :to="urlSlug + item.questionnaireId"
        >
          <div>
            <h6 class="mb-0" v-if="!item.pending">Completed: {{ $date(item.submittedOn).format('DD MMM YYYY') }}</h6>
            <h6 class="mb-0" v-if="item.pending"><i class="icon icon-clock mr-2 pl-0"></i>Awaiting completion</h6>
            <p class="mb-0"><small>Created: {{ $date(item.sentOn).format('DD MMM YYYY') }}</small></p>
          </div>
          <div>
            <span class="btn btn-warning btn-sm">View</span>
          </div>
        </b-link>
      </li>
    </ul>
    <p v-if="itemsComp.length < 1">Nothing to display</p>
  </div>
</template>

<script>
export default {
  name: 'ListQuestionnaires',
  props: {
    items: {type: Array, required: true},
    perPage: {type: Number, required: false, default: 10},
    urlSlug: {type: String, required: true},
    sortBy: {type: String, required: true}
  },
  data () {
    return {
      currentPage: 1,
    }
  },
  computed: {
    itemsComp() {
      var itemsNew = this.items

      // Add pending prop sort by prop provided in desc order
      var mappedItems = itemsNew.map(item => {
        return ({
          ...item, 
          pending: item.submittedOn === null ? true : false
        })
      }).sort((a, b) => {
        return b[this.sortBy].localeCompare(a[this.sortBy]);
      })

      return mappedItems
    },
    rows() {
      return this.itemsComp.length
    },
    itemsForList() {
      return this.itemsComp.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    }
  },
  methods: {
    searchReset: function () {
      this.searchTerm = ''
    }
  },
}
</script>